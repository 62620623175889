import { combineReducers, createSlice } from "@reduxjs/toolkit";

export const URL = "https://owa.pcassa.ru:443/v1"
// export const URL = "http://192.168.0.121:8000/v1"

 export const DataeState = createSlice({
    name: "Datae",
    initialState:{
      Datae:null,
    },
    reducers:{
      setDatae:(state,{payload})=>{
        state.Datae = payload 
      },

    }
  })
export const TokenState = createSlice({
    name: "Token",
    initialState: {
      Token:null
    },
    reducers: {
      setToken:(state,{payload})=>{
        state.Token = payload
      },
    },
   
  });


export const rootReducer = combineReducers({
    Token:TokenState.reducer,
    Datae:DataeState.reducer
  });
 


  export const { setToken } = TokenState.actions;
  export const { setDatae } = DataeState.actions;