import { useCallback, useEffect, useMemo, useState } from "react";
import "./Admin.css";
import { Token } from "../store/globalstate";
import { useSelector } from "react-redux";
import Logo from "../admin/admin_img/Logo.svg";
import { useNavigate } from "react-router-dom";
import { setDatae } from "../store/rootReducer";
import {useDispatch} from 'react-redux'
import { URL } from "../store/rootReducer";

export default function Admin(){
    const[admin, setadmin]= useState('')
    const[inputname, setinputname]= useState('')
    let Tokens = useSelector(Token)
    let RefreshTokens = localStorage.getItem("RefreshToken")
    let Tokense =localStorage.getItem("Tokens")
    const navigate = useNavigate();
    const dispatch = useDispatch()

   
    useEffect(()=>{
        fetch(`${URL}/admin/company`,{
            method:'GET',
            headers: {'Content-Type': 'aptdcation/json',
            'Authorization':`Bearer ${Tokense}`
        }
        })
        .then((response)=> response.json())
        .then((data)=>{
            if(data){
                setadmin(data)
            }
        })
    },[Token])
    let Admin_Company = useCallback(()=>{
        if(admin){
            return <table id="Main_tabel1">
                     {admin?.EKey?.map((element, index)=>{
                         return   <tr id="Main_tr">
                                     <td>{element?.c_name}</td>
                                     <td>{element?.c_inn}</td> 
                                     <td>{element?.c_unique_id}</td>
                                     <td>{element?.c_contact_name}</td>
                                     <td>{element?.c_phone}</td>
                                     <td>{element?.c_email}</td>
                                     <td id="butt"><button id="dani" onClick={()=>{
                                        fetch(`${URL}/admin/company/${element?.c_id}`,{
                                            method:'GET',
                                            headers: {'Content-Type':'aptdcation/json',
                                            'Authorization':`Bearer ${Tokense}`
                                        }
                                        })
                                        .then((response)=> response.json())
                                        .then((data)=>{
                                            if(data){
                                                dispatch(setDatae(data)) 
                                                navigate("/Data")
                                                
                                            }
                                        })
                                    
                                     }}>Данные</button></td>
                                </tr>
                    })}          
                   </table>
        }
    })
    useMemo(()=>{
        fetch(`${URL}/admin/company?q=${inputname}`,{
            method:'GET',
            headers: {'Content-Type': 'aptdcation/json',
            'Authorization':`Bearer ${Tokense}`
        }
        })
        .then((response)=> response.json())
        .then((data)=>{
            if(data){
                setadmin(data)
             
            }
        })
    },[inputname])

    return(
        <div id="Main_tabel">
            <div id="Main_border">
             
            <div id="Main_img"><img src={Logo} /></div> 
            
             <ul id="Main_ul">
                <li>Названия объекта</li>
                <li>ИНН</li>
                <li>Уникальный код</li>
                <li>Контакты лицо</li>
                <li>Номер телефона</li>
                <li>Почта</li>
                <li></li>

             </ul> 
             <div id="Main_input">
                  <input type="string" placeholder="Поиск․․" onChange={(event) => {setinputname(event.target.value)}}/>
                  
              </div> 
             <div id="Main_tabel_content">
               
                <Admin_Company/>
             </div>
             </div>
        </div>
    )
}