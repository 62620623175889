
import './App.css';
import { Routes, Route } from "react-router-dom";
import Signin from "./signin/Signin"
import Admin from './admin/Admin';
import Data from './data/Data';


function App() {

  return(
    <Routes>
      <Route path="/" element={<Signin/>}/>
      <Route path="Admin" element ={<Admin/>}/>
      <Route path="Data" element ={<Data/>}/>
    </Routes>
  )
  
}

export default App;
