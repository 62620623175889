import "./Signin.css";
import { useEffect, useState } from "react";
import React from 'react';
 import { useFormik } from 'formik';
 import * as Yup from 'yup'
 import { useNavigate } from "react-router-dom";
 import {useDispatch} from 'react-redux'
import { setToken, URL } from "../store/rootReducer";
import Fon from "./signin_img/fon.png";




export default function Signin(props) {
  const [error,seterror] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate();


useEffect(() => {
  if(error){
    let password = document.getElementById('password')
    let login  = document.getElementById('login')
    if(login){
     login.setAttribute('class', 'login')
    }
    if(password){
          password.setAttribute('class', "password")
    }
  }
},[error])


  
async function  Main_Page_Restaurant(values){
  
         const loginForm = document.querySelector(".login-form")
          const formData = new FormData(loginForm)
          formData.append('username', values.login)
          formData.append('password', values.password)
             fetch(`${URL}/admin/signin`, {
             method: "POST",
             body: formData,             
          })
          .then((res) => res.json())
          .then((data) => {

            console.log(data);
            dispatch(setToken(data))
            if(data.access_token){
              localStorage.setItem('RefreshToken' ,data.refresh_token)    
              localStorage.setItem( 'Tokens', data.access_token)
                navigate("Admin")
          }else{ 
          seterror(true)
          }
            
          })
          .catch((err) => {
            console.log("Error", err)
          })
    }
           

    const SignupForm = () => {
      const [eys, seteys] = useState(true)
      const formik = useFormik({
        initialValues: {
          password:'',
          login: '',
        },
        validationSchema:Yup.object().shape({
           login: Yup.string().min(4,'Пароли не совпадают').required('Пароли не совпадают'),
          password: Yup.string().min(4,'Пароли не совпадают').required('Пароли не совпадают'),
        }),
        onSubmit: values => {
         Main_Page_Restaurant(values)     
 
        },
      });
      return (
        <form onSubmit={formik.handleSubmit} className ='login-form'>
          <div className="SigninInput">
          <label  htmlFor="login">Логин*
          <input
            placeholder="Логин"
            id="login"
            name="login"
            type="login"
            onChange={formik.handleChange}
            value={formik.values.login}
          />   </label>
            <label htmlFor="password">Пароль*
            <input            
            id="password"
            name="password"
            type={eys == true ? 'password' : 'text'}       
            onChange={formik.handleChange}
            value={formik.values.password}
             />
             </label> <img onClick={()=>seteys(!eys)}  />
             {formik.touched.password && formik.errors.password ? (
             <div className="paseror"><span>{formik.errors.password}</span></div>
            ) : null}
          <button type="submit">Вход</button>
          </div>
          
        </form>
        
      );
     
      
    };
    return (
      <>
        <div className="Signin" style={{ backgroundImage:`url(${Fon})`,}}>
            <div className="SigninBox">     
            <div className="SigninBox1">
                <h3>Войти в аккаунт админа</h3>
               {error && <div className="Signineror">Неправильный Логин или пароль</div>}
                  <SignupForm />
            </div>
         
        </div>
        </div>
        </>
     );
}

