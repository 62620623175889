import "./Data.css";
import {Datae} from "../store/globalstate"
import { useSelector } from "react-redux";
import moment from 'moment'
import { URL } from "../store/rootReducer";

export default function Data(){
    const Datae1 = useSelector(Datae)
    let Tokense =localStorage.getItem("Tokens")

return( 
    <div id="Main_Data">
        <ul id="Main_Data_ul">
              <li>Цена тарифа</li>
              <li>Кассовая станция</li>
              <li>Мобильная касса</li>
              <li>Мобиьный менеджер</li>
              <li>WEB менеджер</li>
              <li>Метод оплати</li>
              <li>День оплаты</li>
              <li>Дата запуска</li>
              <li>Крайний срок</li>
              <li></li>

        </ul>
      
        
        {Datae1?.tarifes?.map((element, index)=>{console.log(element);
        let pay_date = moment(element?.pay_date).format('DD.MM.YYYY')
        let start_license = moment(element?.start_license).format('DD.MM.YYYY')
        let end_license= moment(element?.end_license).format('DD.MM.YYYY')
            return <tr id="Main_Data_tr" >
                 <td>{element?.order_summ}</td>
                 <td>{element?.cass_stantion_count}</td>
                 <td>{element?.mobile_cass_count}</td>
                 <td>{element?.mobile_manager_count}</td>
                 <td>{element?.web_manager_count}</td>
                 <td>{element?.curr_type}</td>
                <td>{pay_date}</td> 
                 <td >{start_license}</td>
                 <td >{end_license}</td>
                 <td >
                    <button className="databutton" id="aktiv"
                     onClick={(() =>{
                        fetch(`${URL}/admin/company/tarif/enable/${element?.order_id}`,{
                                            method:'POST',
                                            headers: {'Content-Type':'aptdcation/json',
                                            'Authorization':`Bearer ${Tokense}`
                                        },
                                        body:JSON.stringify({
                                            order_id:element?.order_id,      
                                          }),
                                        })
                                        .then((response)=> response.json())
                                        .then((data)=>{console.log(data);
                                            if(data.status =='ok'){  console.log(data.status);
                                               alert(
                                               "Тариф активирован"
                                               )
                                              
                                            }
                                        })
                     })}>Активировать</button>
                    <button className="databutton" id="blok"
                      onClick={(() =>{
                        fetch(`${URL}/admin/company/tarif/block/${element?.order_id}`,{
                                            method:'POST',
                                            headers: {'Content-Type':'aptdcation/json',
                                            'Authorization':`Bearer ${Tokense}`
                                        },
                                        body:JSON.stringify({
                                            order_id:element?.order_id,      
                                          }),
                                        })
                                        .then((response)=> response.json())
                                        .then((data)=>{console.log(data);
                                            if(data.status =='ok'){  console.log(data.status);
                                               alert(
                                               "Тариф отключенн"
                                               )
                                              
                                            }
                                        })
                     })}
                    >Блокировать</button>
                 </td>

                  </tr>
        })}
    </div>
)
}